import axios from "axios";

const defaultState = {
  All: [],
  BrandCart: JSON.parse(localStorage.getItem("BrandCart")) || [],
  countFav:0,
  rowCount:'',
  ifNewRow:false,
  Providers:''
};

const actions = {
  CartKeywordDelete: ({ commit }, keyword) => {
    commit("CART_KEYWORD_DELETE", keyword);
  },
  CartAddItem: ({ commit }, item) => {
    return new Promise((resolve, reject) => {
    commit("CART_UPDATED", item);
    if(defaultState.ifNewRow){
      defaultState.ifNewRow=false;
      resolve(true);
    }
    else{
      reject(false);
    }
    });
  },
  CartRemoveItem: ({ commit }, item) => {
    commit("CART_UPDATED_REMOVE", item);
  },
  getData: ({ commit, state }, data) => {
    var arr = [];
    return new Promise((resolve, reject) => {
      axios
        .get("user/test?search=" + data.search)
        .then((response) => {
          for (var i in response.data.data) {
            arr.push({
              id: response.data.data[i].id,
              name: response.data.data[i].name,
              addToCart: false,
              keyword: data.search,
              type: response.data.data[i].type,
            });
          }
          commit("Data_UPDATED", arr);
          if (state.BrandCart) {
            for (var b in state.BrandCart) {
              // console.log(state.BrandCart[b])
              commit("CART_UPDATED_FROM_LOCAL", state.BrandCart[b]);
            }
          }
          resolve(arr);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  Data_UPDATED: (state, All) => {
    state.All = All;
    var length_arr = 0;
    for (var i in state.BrandCart) {
      length_arr += state.BrandCart[i].item.length;
    }
    return state.countFav = length_arr;
  },
  CART_KEYWORD_DELETE: (state, keyword) => {
    for (var i in state.BrandCart) {
      if (state.BrandCart[i].keyword === keyword.keyword) {
        state.BrandCart.splice(i, 1);
      }
    }
    localStorage.setItem("BrandCart", "");
    localStorage.setItem("BrandCart", JSON.stringify(state.BrandCart));
  },
  CART_UPDATED_FROM_LOCAL: (state, item) => {
    for (var s in item.item) {
      for (var i in state.All) {
        if (
          state.All[i].id === item.item[s].id &&
          state.All[i].keyword === item.keyword
        ) {
          state.All[i].addToCart = true;
        }
      }
    }
    localStorage.setItem("BrandCart", "");
    localStorage.setItem("BrandCart", JSON.stringify(state.BrandCart));
  },
  CART_UPDATED: (state, item) => {
    for (var i in state.All) {
      if (state.All[i].id === item.item.id) {
        state.All[i].addToCart = true;
      }
    }
    for (var ii in state.BrandCart) {
      if (state.BrandCart[ii].keyword === item.keyword) {
        state.BrandCart[ii].item.push(item.item);
        localStorage.setItem("BrandCart", "");
        localStorage.setItem("BrandCart", JSON.stringify(state.BrandCart));
        return true;
      }
    }
    state.BrandCart.unshift({
      keyword: item.keyword,
      item: [item.item],
    });
    state.ifNewRow = true;
    localStorage.setItem("BrandCart", "");
    localStorage.setItem("BrandCart", JSON.stringify(state.BrandCart));
  },
  CART_UPDATED_REMOVE: (state, item) => {
    for (var i in state.All) {
      if (state.All[i].id === item.item.id) {
        state.All[i].addToCart = false;
      }
    }
    for (var ii in state.BrandCart) {
      if (state.BrandCart[ii].keyword === item.keyword) {
        for (var iii in state.BrandCart[ii].item) {
          if (state.BrandCart[ii].item[iii].id === item.item.id) {
            state.BrandCart[ii].item.splice(iii, 1);
            localStorage.setItem("BrandCart", "");
            localStorage.setItem("BrandCart", JSON.stringify(state.BrandCart));
            return true;
          }
        }
      }
    }
    localStorage.setItem("BrandCart", "");
    localStorage.setItem("BrandCart", JSON.stringify(state.BrandCart));
  },
};

const getters = {
  All: (state) => state.All,
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
