import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/store";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { BSkeleton } from "bootstrap-vue";
Vue.component("b-skeleton", BSkeleton);
import { VBTooltipPlugin } from "bootstrap-vue";
Vue.use(VBTooltipPlugin);

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Scrollspy from "vue2-scrollspy";
import VueYoutube from "vue-youtube";

var VueScrollTo = require("vue-scrollto");

import VueParticles from "vue-particles";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.prototype.$BASE_URL = "https://api.brandsbank.net/";
// Vue.prototype.$BASE_URL = "https://name.vileo.net/";
// Vue.prototype.$BASE_URL = 'http://localhost:8000/';

axios.defaults.baseURL = "https://api.brandsbank.net/api/";
// axios.defaults.baseURL = "https://name.vileo.net/api/";
// axios.defaults.baseURL = "http://localhost:8000/api/";

Vue.use(VueYoutube);
Vue.use(VueParticles);

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueScrollTo);
Vue.use(Scrollspy);

import "@/assets/scss/style.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
