

const defaultState = {
  DomainsCart: JSON.parse(localStorage.getItem("DomainsCart")) || [],
  AllDomainList:[],
  ifNewRow:false
};

const actions = {
  DomainCartKeywordDelete: ({ commit }, keyword) => {
    commit("DOMAIN_CART_KEYWORD_DELETE", keyword);
  },
  DomainDataUpdate: ({ commit }, data) => {
    commit("DOMAIN_DATA_UPDATE_FROM_API", data);
  },
  DomainsCartAddItem: ({ commit }, item) => {
    return new Promise((resolve, reject) => {
    commit("DOMAIN_CART_UPDATED", item);
    if(defaultState.ifNewRow){
      defaultState.ifNewRow=false;
      resolve(true);
    }
    else{
      reject(false);
    }
    });
  },
  DomainsCartRemoveItem: ({ commit }, item) => {
    commit("DOMAIN_CART_UPDATED_REMOVE", item);
  },
};

const mutations = {
  DOMAIN_DATA_UPDATE_FROM_API:(state,data)=>{
    state.AllDomainList = data.data;
  },
  Data_UPDATED: (state, All) => {
    state.AllDomainList = All;
    var length_arr = 0;
    for (var i in state.DomainsCart) {
      length_arr += state.DomainsCart[i].item.length;
    }
    return state.countFav = length_arr;
  },
  DOMAIN_CART_KEYWORD_DELETE: (state, keyword) => {
    for (var i in state.DomainsCart) {
      if (state.DomainsCart[i].keyword === keyword.keyword) {
        state.DomainsCart.splice(i, 1);
      }
    }
    localStorage.setItem("DomainsCart", "");
    localStorage.setItem("DomainsCart", JSON.stringify(state.DomainsCart));
  },
  CART_UPDATED_FROM_LOCAL: (state, item) => {
    for (var s in item.item) {
      for (var i in state.AllDomainList) {
        if (
          state.AllDomainList[i].domain === item.item[s].domain &&
          state.AllDomainList[i].keyword === item.keyword
        ) {
          state.AllDomainList[i].inCart = true;
        }
      }
    }
    localStorage.setItem("DomainsCart", "");
    localStorage.setItem("DomainsCart", JSON.stringify(state.DomainsCart));
  },
  DOMAIN_CART_UPDATED: (state, item) => {
    for (var i in state.AllDomainList) {
      if (state.AllDomainList[i].domain === item.item.domain) {
        state.AllDomainList[i].inCart = true;
      }
    }
    
    for (var ii in state.DomainsCart) {
      if (state.DomainsCart[ii].keyword === item.keyword) {
        state.DomainsCart[ii].item.push(item.item);
        localStorage.setItem("DomainsCart", "");
        localStorage.setItem("DomainsCart", JSON.stringify(state.DomainsCart));
        return true;
      }
    }
    state.DomainsCart.unshift({
      keyword: item.keyword,
      item: [item.item],
    });
    state.ifNewRow = true;
    localStorage.setItem("DomainsCart", "");
    localStorage.setItem("DomainsCart", JSON.stringify(state.DomainsCart));
  },
  DOMAIN_CART_UPDATED_REMOVE: (state, item) => {
    for (var i in state.AllDomainList) {
      if (state.AllDomainList[i].domain === item.item.domain) {
        state.AllDomainList[i].inCart = false;
      }
    }
    for (var ii in state.DomainsCart) {
      if (state.DomainsCart[ii].keyword === item.keyword) {
        for (var iii in state.DomainsCart[ii].item) {
          if (state.DomainsCart[ii].item[iii].domain === item.item.domain) {
            state.DomainsCart[ii].item.splice(iii, 1);
            localStorage.setItem("DomainsCart", "");
            localStorage.setItem("DomainsCart", JSON.stringify(state.DomainsCart));
            return true;
          }
        }
      }
    }
    localStorage.setItem("DomainsCart", "");
    localStorage.setItem("DomainsCart", JSON.stringify(state.DomainsCart));
  },
};

const getters = {
  DomainsCart: (state) => state.DomainsCart,
  AllDomainList: (state) => state.AllDomainList,
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
