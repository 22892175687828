import Vue from "vue";
import Vuex from "vuex";
import cart from "./modules/cart";
import collaction from "./modules/collaction";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart: cart,
    collaction: collaction,
  },
});
